
export function cityName({ name, properties: { label, address, community_type: layer } }) {
  return layer && layer === 'area-level-1'
    ? name || label
    : `${name || label}${address && address.state ? `, ${address.state}` : ''}`
}

export const authUrl =
  window.location.hostname === 'admin.liveby.com'
    ? 'https://api.liveby.com'
    : 'https://dev.api.liveby.com'

export const filterAccountPages = (account, communities) =>
  Object.keys(communities).map((city) =>
    communities[city].filter((page) =>
      page.properties.brokerages.includes(account.shortname)
    )
  )
