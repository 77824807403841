import React, { Component } from 'react'
import Toast from './toast'
import { removeToast } from '../../store'
import { connect } from 'react-redux'
import './toasts.css'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

class Toasts extends Component {
  render() {
    const { removeToast, autoDismiss, toasts } = this.props
    return (
      <TransitionGroup component='ul' className='toasts'>
        {toasts.map((toast) => {
          const { id } = toast

          return (
            <CSSTransition key={id} classNames='toast' timeout={500}>
              <Toast
                {...toast}
                onDismissClick={() => removeToast(id)}
                autoDismiss={autoDismiss}
              />
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    removeToast: (id) => dispatch(removeToast(id)),
    autoDismiss: (id, duration = 10000) =>
      setTimeout(() => dispatch(removeToast(id)), duration)
  }
}

const mapStateToProps = ({ toasts, neighborhoodUploadStatus }) => ({
  toasts,
  neighborhoodUploadStatus
})

export default connect(mapStateToProps, mapDispatchToProps)(Toasts)
