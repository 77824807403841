import React from 'react'
import './user.css'

export default ({ img, displayName, isDefault, onClick }) => (
  <div className='user-icon-container'>
    {isDefault ? (
      <div className='user-name' onClick={onClick}>
        {displayName
          .split(' ')
          .map((n) => n[0])
          .join('')}
      </div>
    ) : (
      <img
        className='user-icon'
        src={img}
        alt='User avatar'
        onClick={onClick}
      />
    )}
  </div>
)
