/* global import */
import React, { Component } from 'react'
import { Link, Route, Redirect } from 'react-router-dom'
import Loadable from 'react-loadable'
import Toasts from './components/toasts'
import SearchIcon from './Search.svg'
import AccountsIcon from './accounts-icon.svg'
import DBIcon from './database-solid.svg'
import MLSIcon from './components/mls/icon.svg'
import UserPanel from './components/user/user-panel'
import LiveByLogo from './components/liveby-logo'
import importScript from './utility/importScripts'
import './App.css'

const MLS = Loadable({
  loader: () => import('./components/mls'),
  loading: () => <div>Loading...</div>
})

const Search = Loadable({
  loader: () => import('./components/search'),
  loading: () => <div>Loading...</div>
})
const Account = Loadable({
  loader: () => import('./components/account'),
  loading: () => <div>Loading...</div>
})
const Rets = Loadable({
  loader: () => import('./components/rets'),
  loading: () => <div>Loading...</div>
})

importScript('https://kit.fontawesome.com/f8f6500f53.js')

class App extends Component {
  render() {
    if (this.props.location.pathname === '/') return <Redirect to='/search' />
    const searchParam = this.props.location.pathname.split('/')[1]

    const { image, displayName } = this.props.user
    return (
      <main>
        <nav>
          <UserPanel
            img={image.url}
            isDefault={image.isDefault}
            displayName={displayName}
          />
          <ul>
            <SearchTool
              img={SearchIcon}
              path='search'
              currentPath={searchParam}
            />
            <AccountTool
              img={AccountsIcon}
              path='account'
              currentPath={searchParam}
            />
            <RetsTool img={DBIcon} path='rets' currentPath={searchParam} />
            <Tab img={MLSIcon} path='mls' currentPath={searchParam} />
          </ul>
          <LiveByLogo />
        </nav>
        <Toasts />
        <Route
          path='/search'
          render={(props) => <Search {...props} user={this.props.user} />}
        />
        <Route
          path='/account'
          render={(props) => <Account {...props} user={this.props.user} />}
        />
        <Route
          path='/mls'
          render={(props) => <MLS {...props} user={this.props.user} />}
        />
        <Route
          path='/rets'
          render={(props) => <Rets {...props} user={this.props.user} />}
        />
      </main>
    )
  }
}

const SearchTool = ({ img, path, currentPath }) => (
  <li className={path === currentPath ? 'active' : ''}>
    <Link to={`/${path}`} className='tool-option'>
      <img src={img} alt={path} />
    </Link>
  </li>
)

const AccountTool = ({ img, path, currentPath }) => (
  <li className={path === currentPath ? 'active' : ''}>
    <Link to={`/${path}`} className='tool-option'>
      <img src={img} alt={path} />
    </Link>
  </li>
)

const RetsTool = ({ img, path, currentPath }) => (
  <li className={path === currentPath ? 'active' : ''}>
    <Link to={`/${path}`} className='tool-option'>
      <img src={img || ''} alt={path} />
    </Link>
  </li>
)

const Tab = ({ img, path, currentPath }) => (
  <li className={path === currentPath ? 'active' : ''}>
    <Link to={`/${path}`} className='tool-option'>
      <img src={img} alt={path} />
    </Link>
  </li>
)
export default App
