import React from 'react'
export default (props) => (
  <svg
    {...props}
    fill={props.color}
    stroke={props.color}
    width='14'
    height='14'
    viewBox='0 0 14 14'
  >
    <path d='M13.562 1.53L12.502.47 7.03 5.94 1.562.47.5 1.53 5.97 7 .5 12.47l1.062 1.06 5.47-5.47 5.47 5.47 1.06-1.06L8.092 7z' />
  </svg>
)
