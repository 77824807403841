// @flow
/* global fetch */
import PromiseMap from 'promise.map'
import { coordEach, round, bbox, booleanIntersects, bboxPolygon } from '@turf/turf'
import debounce from 'lodash.debounce'
import React from 'react'
import FailedUpLoad from './failed-upload'
const { cityName } = require('../utils')

/**
 * store
 * =====
 *
 * The redux store maintains state for the admin application when state needs
 * to be accessible to multiple UI components.
 *
 * If you need to access the state, you can do so from a connected component by
 * mapping state to props, and mapping actions to props.
 *
 * `thunk` is already included as middleware, so feel free to use it when you
 * need to make async actions.
 *
 * When possible, please follow FSA:
 * https://github.com/acdlite/flux-standard-action#flux-standard-action
 */

//
// API
//

export const API =
  /.*\.liveby\.com/.test(window.location.host) &&
  !window.location.host.includes('local')
    ? 'https://' + window.location.host.replace('admin', 'api') + '/v1'
    : 'https://dev.api.liveby.com/v1'

export const ADMIN_API = process.env.REACT_APP_ADMIN_API_URL || 'https://stage.admin.api.liveby.com'

//
// INITIAL STATE
//
const searchParams = new URLSearchParams(window.location.search)

export const initialState = {
  boundarySubtypes: searchParams.has('layers')
    ? searchParams.get('layers').split(',')
    : ['neighborhood'],
  cityFilter: '',
  cityLookupStatus: '',
  cities: [],
  city: null,
  cityData: {},
  datasets: [],
  neighborhoodsLookupStatus: '',
  neighborhoodUploadStatus: {},
  neighborhoodSaveStatus: '',
  neighborhoods: [],
  neighborhoodsInView: [],
  neighborhoodIds: {},
  neighborhood: null,
  neighborhoodLookupStatus: '',
  toasts: [],
  verifyStatus: '',
  user: {},
  accounts: null,
  mapLocation: null
}

//
// ACTION TYPES
//

export const BOUNDARY_SUBTYPES = 'BOUNDARY_SUBTYPES'
export const CITY = 'CITY'
export const CITY_FILTER = 'CITY_FILTER'
export const CITY_LOOKUP = 'CITY_LOOKUP'
export const CITY_DATA = 'CITY_DATA'
export const NEIGHBORHOOD = 'NEIGHBORHOOD'
export const NEIGHBORHOOD_SAVE = 'SAVE_NEIGHBORHOOD'
export const NEIGHBORHOOD_REMOVE = 'REMOVE_NEIGHBORHOOD'
export const NEIGHBORHOOD_UPLOAD = 'UPLOAD_NEIGHBORHOOD'
export const NEIGHBORHOODS_LOOKUP = 'NEIGHBORHOODS_LOOKUP'
export const ADD_TOAST = 'ADD_TOAST'
export const REMOVE_TOAST = 'REMOVE_TOAST'
export const REMOVE_ALL_TOASTS = 'REMOVE_ALL_TOASTS'
export const VERIFY = 'VERIFY'
export const SET_USER = 'SET_USER'
export const SET_ACCOUNTS = 'SET_ACCOUNTS'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const DATASET_UPDATE = 'DATASET_UPDATE'
export const SET_MAP_LOCATION = 'SET_MAP_LOCATION'

//
// ACTIONS
//
let cityLookups = 0
export function setUser(user) {
  return {
    type: SET_USER,
    payload: user
  }
}

export async function paginate(func) {
  let finished = false
  let index = 0
  while (!finished) {
    finished = await func(index)
    index++
  }
}

export function lookupAccounts(account) {
  return async (dispatch, getState) => {
    const limit = 50
    await paginate(async (idx) => {
      const res = await fetch(
        `${API}/brokerage-update/get-accounts?limit=${limit}&offset=${
          idx * limit
        }&${account ? `?shortname=${account}` : ''}`,
        {
          credentials: 'include',
          mode: 'cors'
        }
      ).catch(console.error)
      if (!res || !res.ok) {
        const errorToast = addToast({
          type: 'error',
          text: 'Accounts lookup failure'
        })
        dispatch(errorToast)
        return true
      }
      const accounts = await res.json()

      dispatch({
        type: SET_ACCOUNTS,
        payload: [...(getState().accounts || []), ...accounts]
      })
      return accounts.length < limit
    })
  }
}

export function saveAccount(account, changes) {
  return async (dispatch, getState) => {
    const res = await fetch(`${API}/brokerage-update/update`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify({
        shortname: account.shortname,
        set: changes
      })
    })
    if (!res || !res.ok) {
      const errorToast = addToast({ type: 'error', text: 'Account not saved' })
      dispatch(errorToast)
      return
    }
    dispatch({
      type: UPDATE_ACCOUNT,
      payload: await res.json()
    })
    dispatch(
      addToast({
        text: 'Account Saved!',
        type: 'success'
      })
    )
  }
}

export function lookupCity(name, type) {
  return function (dispatch, getState) {
    // Do not fire a city lookup until there are at least three key strokes
    if (!name || name.length < 2) {
      // We aren't currently styling when `cityLookupStatus === 'waiting'`
      // but we can
      return dispatch({
        type: CITY_LOOKUP,
        payload: { status: 'waiting', cities: [] }
      })
    }

    // Notify the UI that a lookup is pending
    dispatch({ type: CITY_LOOKUP, payload: { status: 'pending' } })

    const lid = ++cityLookups
    fetch(
      `${ADMIN_API}/v4/boundaries/suggestions?starts-with=${encodeURIComponent(name)}`,
      { credentials: 'include', mode: 'cors' }
    )
      .then(checkStatus)
      .then((cities) => {
        if (cityLookups !== lid) {
          return /* Lookup is stale */
        }

        dispatch({
          type: CITY_LOOKUP,
          payload: {
            cities: cities.data.map(city => ({
              _id: city.id,
              type: 'Feature',
              properties: {
                label: city.name,
                address: city.address,
                id: city.id,
                community_type: city.layer,
                layer: city.layer
              },
              boundingBox: city.boundingBox
            })),
            status: 'completed'
          }
        })
      })
      .catch(dispatchError(dispatch, CITY_LOOKUP))
  }
}

export function getCity(city) {
  if (window.searchMap) {
    const box = bbox(city.boundingBox).reduce((ac, v, i) => {
      ac[Math.floor(i / 2)][i % 2] = v
      return ac
    }, [[], []])

    const bounds = box.reduce((bounds, p) => {
      bounds.extend({ lat: p[1], lng: p[0] })
      return bounds
    }, new window.google.maps.LatLngBounds())

    window.searchMap.fitBounds(bounds)
  }
}

export function setCityFilter(text) {
  return { type: CITY_FILTER, payload: { text } }
}

export function clearCityList() {
  return { type: CITY_LOOKUP, payload: { cities: [] } }
}

export function selectBoundarySubtypes(types) {
  return async function (dispatch, getState) {
    dispatch({
      type: BOUNDARY_SUBTYPES,
      payload: types
    })
    const bounds = getState().mapLocation
    allNeighborhoodsIn({
      west: bounds[0],
      south: bounds[1],
      east: bounds[2],
      north: bounds[3]
    }, getState, dispatch)
  }
}

let zoomErrorToast = null

export const updateMapLocation = debounce(function updateMapLocation(bounds, loc, zoom, dispatch) {
  dispatch(async function (dispatch, getState) {
    dispatch({
      type: SET_MAP_LOCATION,
      payload: {
        bounds: [bounds.west, bounds.south, bounds.east, bounds.north]
      }
    })
    if (loc && zoom) {
      const params = new URLSearchParams(window.location.search)
      params.set('loc', loc.join(','))
      params.set('zoom', zoom)
      window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`)
    }
    if (zoom > 9) {
      allNeighborhoodsIn(bounds, getState, dispatch)
    } else {
      if (!zoomErrorToast) {
        zoomErrorToast = createToast({
          type: 'warning',
          text: 'The map is zoomed too far out to load boundaries. Please zoom in to load all boundaries.'
        })
      }
      dispatch(addToast(zoomErrorToast))
    }
  })
}, 1000)

export function mongoLikeTypes(type) {
  const typesMap = {
    'school-district': '2018schoolDistrict',
    city: 'compositeCommunity',
    neighborhood: 'neighborhood',
    'postal-city': 'consolidatedPostalCity',
    'area-level-2': 'county',
    district: 'district',
    'micro-neighborhood': 'microNeighborhood',
    'school-attendance-area': 'schoolAttendanceArea',
    'area-level-1': 'state',
    subdivision: 'subdivision',
    'postal-code': 'zipcode',
    // Hack to maintain backward compatibility
    '2018schoolDistrict': '2018schoolDistrict',
    compositeCommunity: 'compositeCommunity',
    consolidatedPostalCity: 'consolidatedPostalCity',
    county: 'county',
    microNeighborhood: 'microNeighborhood',
    schoolAttendanceArea: 'schoolAttendanceArea',
    state: 'state',
    zipcode: 'zipcode'
  }

  return typesMap[type]
}

export function v4LikeTypes(type) {
  const typesMap = {
    '2018schoolDistrict': 'school-district',
    compositeCommunity: 'city',
    neighborhood: 'neighborhood',
    consolidatedPostalCity: 'postal-city',
    county: 'area-level-2',
    district: 'district',
    microNeighborhood: 'micro-neighborhood',
    schoolAttendanceArea: 'school-attendance-area',
    state: 'area-level-1',
    subdivision: 'subdivision',
    zipcode: 'postal-code'
  }

  return typesMap[type]
}

let neighborhoodController = new AbortController()

async function allNeighborhoodsIn(bounds, getState, dispatch) {
  const { boundarySubtypes } = getState()

  let nextNeighborhoods = { data: [], meta: {} }
  neighborhoodController.abort()
  neighborhoodController = new AbortController()
  const signal = neighborhoodController.signal

  const toast = addToast({
    type: 'info',
    text: 'Loading shapes'
  })
  dispatch(toast)
  let page = 0
  do {
    nextNeighborhoods = await fetch(
      `${ADMIN_API}/v4/boundaries?include-geometry=true&limit=500&offset=${page * 500}&${boundarySubtypes.map(l => `layer=${l}`).join('&')}&bounding-box-east=${bounds.east}&bounding-box-north=${bounds.north}&bounding-box-west=${bounds.west}&bounding-box-south=${bounds.south}`,
      {
        mode: 'cors',
        credentials: 'include',
        signal
      }
    )
      .then(checkStatus)
      .catch((e) => {
        if (e.name === 'AbortError') return { data: [], pagination: {} }
        throw e
      })
    if (signal.aborted) break
    const { neighborhoodIds, neighborhoods } = getState()
    const asMongoish = nextNeighborhoods.data
      .filter(n => !(n.id in neighborhoodIds))
      .map(v4ToMongo)
    const allNeighborhoods = [...neighborhoods, ...asMongoish]
    for (const n of asMongoish) {
      if (!neighborhoodIds[n._id]) {
        neighborhoodIds[n._id] = 1
      }
    }
    updateNeighborhoodsStore(allNeighborhoods, neighborhoodIds, 'pending', dispatch)
    dispatch(
      addToast({
        ...toast.payload
      })
    )
    page++
  } while (nextNeighborhoods.pagination.pageTotal >= nextNeighborhoods.pagination.limit)
  dispatch(removeToast(toast.payload.id))
  const { neighborhoods, neighborhoodIds } = getState().neighborhoodIds
  return updateNeighborhoodsStore(neighborhoods, neighborhoodIds, 'completed', dispatch)
}

function v4ToMongo({ geometry, id, name, layer, ...n }) {
  return {
    type: 'Feature',
    _id: id,
    id,
    properties: {
      ...n,
      label: name || 'Unnamed Boundary',
      community_type: layer,
      layer
    },
    geometry
  }
}

function updateNeighborhoodsStore(neighborhoods, neighborhoodIds, status, dispatch) {
  dispatch({
    type: NEIGHBORHOODS_LOOKUP,
    payload: {
      neighborhoods,
      neighborhoodIds,
      status
    }
  })
}

export function selectNeighborhood(id) {
  return (dispatch) => {
    // Notify the UI that a lookup is pending
    dispatch({ type: NEIGHBORHOOD, payload: { status: 'pending' } })
    fetch(`${ADMIN_API}/v4/boundaries/${id}`, { credentials: 'include', mode: 'cors' })
      .then(checkStatus)
      .then(({ data: neighborhood }) => {
        dispatch({
          type: NEIGHBORHOOD,
          payload: {
            neighborhood: v4ToMongo(neighborhood),
            status: 'completed'
          }
        })
      })
  }
}

let id = 0

const defaultOptions = {
  type: 'info'
}

/**
 * ###createToast ({type, id, text, progress})
 *
 * Helper function called by `addToast()`
 *
 * Initializes an id if none are passed to `addToast()`
 * Adds any passed in options to the default options and returns an object.
 *
 */
export function createToast(options: {
  type: 'success' | 'error' | 'warning' | 'info' | 'progress',
  id?: number,
  text?: string,
  progress?: number
}) {
  return {
    ...defaultOptions,
    id: options.id || id++,
    ...options
  }
}

/**
 * ### addToast({type, id, text, progress})
 *
 * Initializes a toast notification when dispatched.
 *
 * - `options`: An Object which has the following properties:
 *   - `type`: An optional String that defines what type of notification the toast will be.
 *             Must be one of: "success", "error", "warning", "info", "progress".
 *
 * ##### Example:
 *
 *    addToast({
 *      type: 'success',
 *      text: 'You are successful beyond your wildest imaginings!'
 *    })
 *
 */
export function addToast(
  options: {
    type: 'success' | 'error' | 'warning' | 'info' | 'progress',
    id?: number,
    text?: string,
    progress?: number
  } = {}
) {
  return {
    type: ADD_TOAST,
    payload: createToast(options)
  }
}

/**
 * ### removeToast (id)
 *
 * Removes a toast notification when dispatched.
 *
 * - `id`: An identifier of the toast to be removed
 *
 * ##### Example:
 *
 *     removeToast(toast.id)
 *
 */
export function removeToast(id: number) {
  return {
    type: REMOVE_TOAST,
    payload: { id }
  }
}

export function removeAllToasts() {
  return {
    type: REMOVE_ALL_TOASTS
  }
}

export function verify(neighborhood) {
  return verification('verify', neighborhood._id)
}

export function unverify(neighborhood) {
  return verification('unverify', neighborhood._id)
}

function verification(endpoint, _id) {
  return function (dispatch) {
    dispatch({
      type: VERIFY,
      payload: { neighborhood: _id, status: 'pending' }
    })

    fetch(`${API}/verification/${endpoint}?_id=${_id}`, {
      credentials: 'include'
    })
      .then(checkStatus)
      .then(({ verified }) => {
        dispatch({
          type: VERIFY,
          payload: {
            status: 'completed',
            neighborhood: _id,
            verified
          }
        })
      })
      .catch((e) => {
        dispatch(
          addToast({
            text: 'Failed to update neighborhood verification status',
            type: 'error'
          })
        )
        dispatchError(dispatch, NEIGHBORHOOD_SAVE)
        console.error(e)
      })
  }
}

// Fetch distinct boundary types

export const getCommunityTypes = {
  Community: 'city',
  'Postal City': 'postal-city',
  District: 'district',
  Neighborhood: 'neighborhood',
  'Micro Neighborhood': 'micro-neighborhood',
  Subdivision: 'subdivision',
  'Postal Code': 'postal-code',
  'School District': 'school-district',
  'School Attendance Area': 'school-attendance-area',
  State: 'area-level-1',
  County: 'area-level-2'
}

export const typeNameLookup = Object.fromEntries(Object.entries(getCommunityTypes).map(([key, value]) => [value, key]))

export const mongoToV4 = {
  Community: 'city',
  'Postal City': 'postal-city',
  District: 'district',
  Neighborhood: 'neighborhood',
  'Micro Neighborhood': 'micro-neighborhood',
  Subdivision: 'subdivision',
  'Postal Code': 'postal-code',
  'School District': 'school-district',
  'School Attendance Area': 'school-attendance-area',
  State: 'area-level-1',
  County: 'area-level-2'
}

export const getVirtualLayers = {
  Municipality: 'municipality',
  Mosaic: 'mosaic'
}

export function uploadNeighborhoods(neighborhoods) {
  return async function (dispatch, getState) {
    const toast = addToast({
      type: 'progress',
      text: `Uploading ${neighborhoods.length} neighborhoods`,
      progress: 0
    })
    try {
      let amountDone = 0
      let amountSucessful = 0
      let amountFailed = 0
      const failedBoundaries = []
      dispatch(toast)
      await PromiseMap(
        neighborhoods,
        async (neighborhood) => {
          if (!neighborhood.properties.label) throw new Error('No label provided for boundary')
          if (!neighborhood.geometry) throw new Error('No geometry provided for boundary')
          if (!neighborhood.properties.community_type) throw new Error('No type provided for boundary')
          try {
            await saveNeighborhood(
              neighborhood,
              true
            )(dispatch, getState)
            amountSucessful += 1
          } catch (e) {
            dispatch(
              addToast({
                ...toast.payload,
                progress: amountDone / neighborhoods.length,
                text: `Uploading ${neighborhoods.length} neighborhoods`
              })
            )
            addToast({
              type: 'error',
              text: 'Failed to upload boundary: ' + e.message
            })
            amountFailed++
            neighborhood.properties.error = e.message
            failedBoundaries.push(neighborhood)
            console.error(e)
          }
          amountDone++
          dispatch(
            addToast({
              ...toast.payload,
              progress: amountDone / neighborhoods.length,
              text: `Uploading ${neighborhoods.length} neighborhoods`
            })
          )
        },
        5
      )

      dispatch(
        addToast({
          ...toast.payload,
          progress: 1,
          text: `Successfully uploaded ${amountSucessful} of ${neighborhoods.length} neighborhoods!`,
          type: 'success'
        })
      )

      if (amountFailed > 0) {
        dispatch(
          addToast({
            text: <FailedUpLoad amountFailed={amountFailed} failedBoundaries={failedBoundaries} total={neighborhoods.length} />,
            type: 'error'
          })
        )
      }

      await new Promise((resolve, reject) => setTimeout(resolve, 1000))
    } catch (e) {
      dispatch(
        addToast({
          ...toast.payload,
          text: `Failed to upload ${neighborhoods.length} neighborhoods`,
          type: 'error'
        })
      )
      console.error(e)
    }
  }
}

export function saveNeighborhood(neighborhood, noToast = false) {
  return function (dispatch) {
    dispatch({ type: NEIGHBORHOOD_SAVE, payload: { status: 'pending' } })
    if (
      neighborhood.properties.archive &&
      neighborhood.properties.archive !== false
    ) {
      neighborhood.properties.brokerages = []
    }
    if (!neighborhood._id && neighborhood.properties._id) { // put _id from properties in top level to make sure neighborhood isnt duplicated.
      neighborhood._id = neighborhood.properties._id
    }
    // Cast api v4 types back to mongo types
    if (neighborhood.properties.community_type) {
      neighborhood.properties.community_type = mongoLikeTypes(neighborhood.properties.community_type)
    }

    return fetch(`${ADMIN_API}/update-neighborhood`, {
      method: 'POST',
      body: JSON.stringify({ neighborhood }),
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      credentials: 'include'
    })
      .then(checkStatus)
      .then(async (updates) => {
        if (neighborhood.properties.banned || neighborhood.properties.archive) {
          dispatch({
            type: NEIGHBORHOOD_REMOVE,
            payload: {
              status: 'completed',
              neighborhood: updates
            }
          })
        }
        const v4Res = await fetch(`${ADMIN_API}/v4/boundaries/${updates._id}`, { credentials: 'include', mode: 'cors' })
          .then(checkStatus)
        const v4Neighborhood = v4ToMongo(v4Res.data)
        dispatch({
          type: NEIGHBORHOOD_SAVE,
          payload: {
            status: 'completed',
            neighborhood: v4Neighborhood
          }
        })
        !noToast &&
        dispatch(
          addToast({
            type: 'success',
            text: 'Saved Neighborhood'
          })
        )
        return Promise.resolve()
          .then(() =>
            dispatch({ type: NEIGHBORHOOD_SAVE, payload: { status: 'ready' } })
          )
          .then(() => v4Neighborhood)
      })
      .catch((e) => {
        console.error(e)
        dispatch(
          addToast({
            text: 'Failed to save neighborhood to the database: ' + neighborhood.properties.label || neighborhood._id,
            type: 'error'
          })
        )
        dispatchError(dispatch, NEIGHBORHOOD_SAVE)
        throw e
      })
  }
}

//
// ACTION REDUCER
//

/**
 * reduce (state, action)
 * ======================
 * Handle an action by returning a new state tree in response to an action
 * being fired.
 *
 * Currently we are using a single reducer for simplicity.
 */
function reducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case CITY:
      return { ...state, city: payload.city }

    case CITY_FILTER:
      return { ...state, cityFilter: payload.text }

    case SET_USER:
      return { ...state, user: payload }

    case CITY_DATA:
      return error
        ? handleError(state, payload)
        : {
            ...state,
            cityData: payload,
            cityFilter:
              payload && payload.name
                ? cityName(payload)
                : state.cityFilter
          }

    case CITY_LOOKUP:
      return error
        ? handleError(state, payload)
        : {
            ...state,
            cityLookupStatus: payload.status || state.cityLookupStatus,
            cities: payload.cities || state.cities
          }
    case BOUNDARY_SUBTYPES: {
      const neighborhoods = state.neighborhoods.filter(n => boundaryHasLayers(n, payload))
      return {
        ...state,
        boundarySubtypes: payload,
        neighborhoods,
        neighborhoodsInView: state.neighborhoodsInView.filter(n => boundaryHasLayers(n, payload)),
        neighborhoodIds: neighborhoods.filter(n => boundaryHasLayers(n, payload)).reduce((o, n) => ({ ...o, [n._id]: 1 }), {})
      }
    }
    case NEIGHBORHOOD:
      return error
        ? handleError(state, payload)
        : {
            ...state,
            neighborhoodLookupStatus:
              payload.status || state.neighborhoodLookupStatus,
            neighborhood: payload.neighborhood || state.neighborhood
          }
    case NEIGHBORHOODS_LOOKUP: {
      return error
        ? handleError(state, payload)
        : {
            ...state,
            neighborhoodsLookupStatus:
              payload.status || state.neighborhoodsLookupStatus,
          neighborhoods: payload.neighborhoods || state.neighborhoods,
          neighborhoodsInView: state.mapLocation
            ? (payload.neighborhoods || state.neighborhoods).filter(n => {
              return booleanIntersects(n, bboxPolygon(state.mapLocation.flat()))
            })
            : [],
          neighborhoodIds: payload.neighborhoodIds || state.neighborhoodIds
        }
    }
    case NEIGHBORHOOD_UPLOAD: {
      const existingIndex = state.neighborhoodIds[payload._id]

      if (existingIndex != null) {
        // If a neighborhood object with the same _id already exists, replace it
        return {
          ...state,
          neighborhoods: [
            ...state.neighborhoods.slice(0, existingIndex),
            payload,
            ...state.neighborhoods.slice(existingIndex + 1)
          ]
        }
      } else {
        // If no neighborhood object with the same _id exists, insert the payload
        const idx = state.neighborhoods.findIndex(
          (a) =>
            a.properties.label
              .toLowerCase()
              .localeCompare(payload.properties.label.toLowerCase()) >= 0
        )
        const neighborhoods = payload
          ? [
              ...state.neighborhoods.slice(0, idx),
              payload,
              ...state.neighborhoods.slice(idx)
            ]
          : state.neighborhoods
        return {
          ...state,
          neighborhoods,
          neighborhoodsInView: state.mapLocation
            ? (payload.neighborhoods || state.neighborhoods).filter(n => {
              return booleanIntersects(n, bboxPolygon(state.mapLocation.flat()))
            })
            : []
        }
      }
    }
    case NEIGHBORHOOD_REMOVE: {
      let neighborhoods = [...state.neighborhoods]
      const ids = { ...state.neighborhoodIds }
      if ('neighborhood' in payload) {
        neighborhoods = state.neighborhoods.filter((neighborhood) => {
          return neighborhood._id !== payload.neighborhood._id
        })
        delete ids[payload._id]
      }
      return {
        ...state,
        neighborhoodSaveStatus: payload.status || state.neighborhoodSaveStatus,
        neighborhoods,
        neighborhoodIds: ids,
        neighborhoodsInView: state.mapLocation
          ? (payload.neighborhoods || state.neighborhoods).filter(n => {
            return booleanIntersects(n, bboxPolygon(state.mapLocation.flat()))
          })
          : []
      }
    }
    case NEIGHBORHOOD_SAVE: {
      const neighborhoods = [...state.neighborhoods]
      if ('neighborhood' in payload) {
        if (payload.neighborhood._id in state.neighborhoodIds) {
          const idx = state.neighborhoods.findIndex((a) => a._id === payload.neighborhood._id)
          if (idx !== -1) {
            neighborhoods[idx] = payload.neighborhood
          }
        } else {
          if (boundaryHasLayers(payload.neighborhood, state.boundarySubtypes)) {
            neighborhoods.push(payload.neighborhood)
            neighborhoods.sort((a, b) => a.properties.label.localeCompare(b.properties.label))
          }
        }
      }
      return {
        ...state,
        neighborhoodSaveStatus: payload.status || state.neighborhoodSaveStatus,
        neighborhoods,
        neighborhoodsInView: state.mapLocation
          ? (payload.neighborhoods || state.neighborhoods).filter(n => {
            return booleanIntersects(n, bboxPolygon(state.mapLocation.flat()))
          })
          : [],
        neighborhoodIds: neighborhoods.reduce((o, n) => ({ ...o, [n._id]: 1 }), {}),
        neighborhood:
          state.neighborhood &&
          payload.neighborhood &&
          payload.neighborhood._id === state.neighborhood._id
            ? { ...state.neighborhood, ...payload.neighborhood }
            : state.neighborhood
      }
    }
    case DATASET_UPDATE: {
      return {
        ...state,
        datasets: payload
      }
    }
    case ADD_TOAST: {
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id === payload.id)[0]
          ? state.toasts.map((toast) =>
            toast.id === payload.id ? payload : toast
          )
          : [...state.toasts, payload]
      }
    }
    case REMOVE_TOAST: {
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== payload.id)
      }
    }
    case REMOVE_ALL_TOASTS: {
      return {
        ...state,
        toasts: []
      }
    }
    case VERIFY: {
      return error
        ? handleError(state, payload)
        : {
            ...state,
            verifyStatus: payload.status || state.verifyStatus,
            neighborhoods:
              'verified' in payload
                ? state.neighborhoods.map((neighborhood) => {
                  return neighborhood._id === payload.neighborhood
                    ? {
                      ...neighborhood,
                      meta: {
                        ...neighborhood.meta,
                        verified: payload.verified
                      }
                    }
                    : neighborhood
                })
                : state.neighborhoods,
            neighborhood:
              state.neighborhood &&
              payload.neighborhood === state.neighborhood._id &&
              'verified' in payload
                ? {
                    ...state.neighborhood,
                    meta: {
                      ...state.neighborhood.meta,
                      verified: payload.verified
                    }
                  }
                : state.neighborhood
          }
    }
    case SET_ACCOUNTS:
      return {
        ...state,
        accounts: payload
      }
    case UPDATE_ACCOUNT:
      return {
        ...state,
        accounts: state.accounts.map((ac) =>
          ac.shortname === payload.shortname ? payload : ac
        )
      }
    case SET_MAP_LOCATION: {
      return {
        ...state,
        mapLocation: payload.bounds,
        neighborhoodsInView: state.neighborhoods.filter(n => {
          return booleanIntersects(n, bboxPolygon(payload.bounds))
        })
      }
    }
    default:
      return state
  }
}

export default reducer

// Utility function to handle UI errors/notifications in a consistent manner.
// Probably should be handled by some redux middleware...
function handleError(state, error) {
  return {
    ...state,
    uiNotification: typeof error === 'string' ? error : error.message
  }
}

// Dispatch the error according to FSA
function dispatchError(dispatch, type) {
  return function errorDispatcher(err) {
    dispatch({ type, payload: err, error: true })
  }
}

// Check for server error (500) responses from API
function checkStatus(res) {
  if (!res.ok) {
    throw new Error(res.statusText)
  }
  return res.json()
}

export function boundaryHasLayers(boundary, layers) {
  return layers.some((layer) => [...boundary.properties.virtualLayers, boundary.properties.layer].includes(layer))
}
