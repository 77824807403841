import React from 'react'
export default () => (
  <svg className='liveby-logo' viewBox='0 0 182.2 170.15'>
    <defs />
    <title>LiveBy Logo</title>
    <g id='Layer_2'>
      <g id='Layer_1-2'>
        <path
          fill='#ffac47'
          d='M91.76 96.6l17.91 72a2 2 0 0 0 3.76.28l8.11-19.64a2 2 0 0 0 .15-.81l-1.47-53.08a2 2 0 0 0-2-1.93l-24.56.68a2 2 0 0 0-1.9 2.5zM172.3 26.34l9.7-23.6A2 2 0 0 0 179.95 0l-52.67 7a2 2 0 0 0-1.72 2l.49 17.77a2 2 0 0 0 2 1.93l42.43-1.17a2 2 0 0 0 1.82-1.19z'
        />
        <path
          fill='#ff7674'
          d='M1 27.4l56.25 31a2 2 0 0 0 2.94-1.79l-1.06-38.3a2 2 0 0 0-2.25-1.91L1.72 23.69A2 2 0 0 0 1 27.4zM129.66 85.43l17.17-.43a2 2 0 0 0 1.78-1.23l18.81-45.56a2 2 0 0 0-1.89-2.74l-37.22 1a2 2 0 0 0-1.93 2l1.24 45a2 2 0 0 0 2.04 1.96z'
        />
        <path
          fill='#78cfe6'
          d='M132.5 122.74l11.22-27.19a2 2 0 0 0-1.89-2.74l-12 .33a2 2 0 0 0-1.93 2l.74 26.86a2 2 0 0 0 3.86.74zM66.85 16.83l1.28 46.38a2 2 0 0 0 1 1.68l16.3 9a2 2 0 0 1 1 1.26L88.87 85a2 2 0 0 0 2 1.51l27.13-.76a2 2 0 0 0 1.93-2l-2-73.24a2 2 0 0 0-2.25-1.91L89.55 12l-21 2.79a2 2 0 0 0-1.7 2.04z'
        />
      </g>
    </g>
  </svg>
)
