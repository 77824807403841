import React, { Component } from 'react'
import UserIcon from './user-icon'
import UserLogoutIcon from './user-logout-icon'
import { authUrl } from '../../utils'
import './user.css'

export default class UserPanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false
    }
  }

  handleUserIconClick = (evt) => this.setState({ open: !this.state.open })

  handleUserLogoutClick = async (evt) => {
    fetch(`${authUrl}/google/logout`, {
      mode: 'no-cors',
      credentials: 'include'
    })
      .then(() => {
        window.location.reload()
      })
      .catch((e) => {
        console.log('Error: ' + e)
      })
  }

  handleUserContainerClick = (evt) => {
    const bodyListener = (evt) => {
      if (!evt.target.closest('.user-container')) {
        document.body.removeEventListener('click', bodyListener)
        this.setState({ open: !this.state.open })
      }
    }

    document.body.addEventListener('click', bodyListener)
    this.setState({ open: !this.state.open })
  }

  render() {
    const open = this.state.open

    return (
      <div className='user-container' onClick={this.handleUserContainerClick}>
        <UserIcon {...this.props} onClick={this.handleUserIconClick} />
        {open ? (
          <div className='user-panel'>
            <span className='user-full-name'>{this.props.displayName}</span>
            <button
              className='user-logout'
              onClick={this.handleUserLogoutClick}
            >
              <UserLogoutIcon />
              Log Out
            </button>
          </div>
        ) : null}
      </div>
    )
  }
}
