import { useEffect } from 'react'

const importScript = (resourceUrl) => {
  const script = document.createElement('script')
  script.src = resourceUrl
  script.async = true
  script.crossorigin = 'anonymous'
  document.body.appendChild(script)

  return () => {
    document.body.removeChild(script)
  }
}

export default importScript
