import React from 'react'

export default function FailedUpLoad({ amountFailed, failedBoundaries, total }) {
  async function downloadFailed() {
    const json = JSON.stringify({
      type: 'FeatureCollection',
      features: failedBoundaries
    })
    const blob = new Blob([json], { type: 'application/json' })

    const href = await URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = href
    link.download = 'Failed Boundaries.geojson'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  return (
    <div className='table'>
      Failed to upload {amountFailed} of {total} neighborhoods!
      <button className='btn skip' onClick={downloadFailed}>Downlaod Failed Boundaries</button>
    </div>
  )
}
