import React, { Component } from 'react'
import classnames from 'classnames'
import CloseSvg from '../search/profile/close'
import './toasts.css'

const autoDismissTypes = ['success', 'warning']
const dismissTimeout = 7000

export default class Toast extends Component {
  constructor() {
    super()
    this.state = {
      mounted: false
    }
  }

  componentDidMount() {
    this.setState({ mounted: true })
    if (autoDismissTypes.includes(this.props.type)) {
      this.props.autoDismiss(
        this.props.id,
        this.props.timeout || dismissTimeout
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !autoDismissTypes.includes(this.props.type) &&
      autoDismissTypes.includes(nextProps.type)
    ) {
      this.props.autoDismiss(nextProps.id, nextProps.timeout || dismissTimeout)
    }
  }

  render() {
    const { type, text, onDismissClick, progress } = this.props
    const { mounted } = this.state

    return (
      <li className={classnames('toast', mounted ? type : '')}>
        {type === 'progress' ? (
          <div className='progress-bar'>
            {progress != null ? (
              <span className='progress-value'>
                {text && `${text} - `} {Math.round(progress * 100)}%
              </span>
            ) : null}
            <div
              className='progress-status progress-status-striped active'
              role='progressbar'
              aria-valuenow={Math.round(progress * 100)}
              aria-valuemin='0'
              aria-valuemax='100'
              style={{ width: `${Math.round(progress * 100)}%` }}
            />
          </div>
        ) : (
          <p className='toast-content'>{text}</p>
        )}
        <button className='toast-dismiss' onClick={onDismissClick}>
          <CloseSvg color='#fff' className='toast-close-icon' />
        </button>
      </li>
    )
  }
}
