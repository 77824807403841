import React from 'react'

export default () => (
  <svg width='17' height='16' viewBox='0 0 17 16'>
    <title>Log Out Icon</title>
    <path
      d='M5.293 3.293l1.414 1.414L4.415 7h7.586v2H4.416l2.292 2.293-1.413 1.414L.587 8l4.707-4.707zm9.533 10.53V2H9V0h7.826v15.824h-7.41v-2h5.41z'
      fill='#8F91A0'
      fillRule='evenodd'
      fillOpacity='.7'
    />
  </svg>
)
